<template>
  <div class="wrapper">
    <el-form ref="formScoreProfile" class="score-profile-form" :model="scoreProfileData" label-width="">
      <table class="table-box" style="width:100%;text-align:center" border>
        <tr class="table-bg1">
          <td>系统评级</td>
          <td>系统评分</td>
          <td colspan="2">最终评分</td>
          <td>审核建议</td>
        </tr>
        <tr>
          <td>
            <TagLv v-if="scoreProfileData.sysHitGrade == 'A'" name="A"></TagLv>
            <TagLv v-if="scoreProfileData.sysHitGrade == 'B'" name="B"></TagLv>
            <TagLv v-if="scoreProfileData.sysHitGrade == 'C'" name="C"></TagLv>
            <TagLv v-if="scoreProfileData.sysHitGrade == 'D'" name="D"></TagLv>
          </td>
          <td>
            {{ scoreProfileData.sysHitScore >= 0 ? scoreProfileData.sysHitScore + '分' : ' / ' }}
            <el-button v-if="scoreProfileData.sysHitScore >= 0" style="margin-left:0px" type="primary" size="mini" @click="scoreBillList">详情</el-button>
          </td>
          <td colspan="2">
            <span>
              <TagLv v-if="scoreProfileData.finalHitGrade == 'A'" name="A"></TagLv>
              <TagLv v-if="scoreProfileData.finalHitGrade == 'B'" name="B"> </TagLv>
              <TagLv v-if="scoreProfileData.finalHitGrade == 'C'" name="C"></TagLv>
              <TagLv v-if="scoreProfileData.finalHitGrade == 'D'" name="D"> </TagLv>
            </span>

            <el-button
              v-if="scoreProfileData.sysHitScore >= 0"
              style="margin-left:5px"
              type="primary"
              size="small"
              @click="updateFinalGrade"
              :disabled="!isShowBtn(AUTH_BTN.review_list_detail_update_final_grade)"
              >修改</el-button
            >
          </td>
          <td>
            <span style="color:#bd3124">{{ scoreProfileData.sysHitSuggestDesc }}</span>
          </td>
        </tr>
        <tr class="table-bg1">
          <td>芝麻分</td>
          <td>信用情况</td>
          <td>租机历史</td>
          <td>涉诉情况</td>
          <td>是否学生在读</td>
        </tr>
        <tr>
          <td>
            <el-form-item label=" " prop="zmScore" :rules="[{ required: true, message: '该项不能为空', trigger: 'blur' }]">
              <el-input style="width:70%;" v-model="scoreProfileData.zmScore" size="mini" placeholder="请输入"></el-input>
            </el-form-item>
          </td>
          <td>
            <el-form-item label=" " prop="creditStatus" :rules="[{ required: true, message: '该项不能为空', trigger: 'blur' }]">
              <SelectDict width="70%" size="mini" v-model="scoreProfileData.creditStatus" dictCode="member:creditStatus" :clearable="false" />
            </el-form-item>
          </td>
          <td>
            <el-form-item label=" " prop="rentHistory" :rules="[{ required: true, message: '该项不能为空', trigger: 'blur' }]">
              <SelectDict width="70%" size="mini" v-model="scoreProfileData.rentHistory" dictCode="member:rentHistory" :clearable="false" />
            </el-form-item>
          </td>
          <td>
            <el-form-item label=" " prop="involveStatus" :rules="[{ required: true, message: '该项不能为空', trigger: 'blur' }]">
              <SelectDict width="80%" size="mini" v-model="scoreProfileData.involveStatus" dictCode="member:involveStatus" :clearable="false" />
            </el-form-item>
          </td>
          <td>
            <el-form-item label=" " prop="inSchoolStatus" :rules="[{ required: true, message: '该项不能为空', trigger: 'blur' }]">
              <SelectDict width="80%" size="mini" v-model="scoreProfileData.inSchoolStatus" dictCode="member:inSchoolStatus" :clearable="false" />
            </el-form-item>
          </td>
        </tr>
        <tr class="table-bg1">
          <td colspan="2">工作单位</td>
          <td>岗位</td>
          <td>薪资</td>
          <td style="background:#fff" rowspan="2">
            <el-button type="primary" size="small" @click="saveProfile">保存</el-button>
          </td>
        </tr>
        <tr>
          <td colspan="2">
            <el-form-item label=" " prop="workCompany">
              <el-input style="width:80%;" v-model="scoreProfileData.workCompany" size="mini" placeholder="请输入"></el-input>
            </el-form-item>
          </td>
          <td>
            <el-form-item label=" " prop="jobCategory">
              <SelectDict width="80%" size="mini" v-model="scoreProfileData.jobCategory" dictCode="member:jobCategory"></SelectDict>
            </el-form-item>
          </td>
          <td>
            <el-form-item label=" " prop="income">
              <el-input style="width:80%;" size="mini" v-model="scoreProfileData.income" placeholder="请输入"></el-input>
            </el-form-item>
          </td>
        </tr>
      </table>
    </el-form>

    <el-dialog title="系统评分详情" :visible.sync="scoreBillInfoDialog" width="800px">
      <div class="score-bill-title">
        <span>系统等级：{{ scoreProfileData.sysHitGrade }}</span>
        <span>系统评分：{{ scoreProfileData.sysHitScore }}</span>
        <span>系统建议：{{ scoreProfileData.sysHitSuggestDesc }}</span>
      </div>
      <div class="score-bill-table">
        <tp-table :showPagination="false" :isNeedSerialNumber="true" :tableData="scoreBillInfo.billList" :columns="columns" />
      </div>
    </el-dialog>

    <el-dialog title="最终等级修改" :visible.sync="updateFinalGradeDialog" width="800px">
      <div>
        <el-radio-group v-model="finalGradeData.grade">
          <el-radio @click.native.prevent="clickFinalGrade(item)" v-for="item in gradeList" :key="item.value" :label="item.value">
            {{ item.name }}
          </el-radio>
        </el-radio-group>
      </div>
      <div class="final-grade-remark">
        <span>备注</span>
        <el-input style="width:350px;" v-model="finalGradeData.remark" size="mini" placeholder="请输入"></el-input>
      </div>
      <div slot="footer">
        <el-button @click="updateFinalGradeDialog = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveFinalGrade" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getScoreBillInfoAPI, hitOrderScoreProfileAPI, updateFinalHitGradeAPI } from './api'
import SelectDict from '@/views/components/select-dict.vue'
import { getDictEnumListAPI } from '@/enum/dict'
import { authBtnMixin } from '@/mixins/authBtnMixin'

const columns = [
  {
    label: '评分项命中规则',
    prop: 'ruleName',
    minWidth: '300',
    customRender(h, row) {
      return <p>{row['ruleName']}</p>
    }
  },
  {
    label: '分值',
    prop: 'hitScore',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['hitScore']}</p>
    }
  },
  {
    label: '审核建议',
    prop: 'hitSuggestDesc',
    minWidth: '100',
    customRender(h, row) {
      return <div>{row['hitSuggestDesc']}</div>
    }
  }
]

export default {
  name: 'ScoreProfile',
  components: { SelectDict },
  mixins: [authBtnMixin],
  props: {
    scoreProfileInfo: {
      type: Object,
      default: () => {}
    },
    flag: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      columns,
      scoreProfileData: {
        orderNo: '',
        sysHitScore: '',
        sysHitSuggestDesc: '',
        sysHitGrade: '',
        finalHitGrade: '',
        zmScore: '',
        creditStatus: '',
        rentHistory: '',
        involveStatus: '',
        inSchoolStatus: '',
        workCompany: '',
        jobCategory: '',
        jobName: '',
        income: ''
      },
      scoreBillInfoDialog: false,
      scoreBillInfo: {},
      gradeList: [],
      updateFinalGradeDialog: false,
      finalGradeData: {
        grade: '',
        remark: ''
      }
    }
  },
  watch: {
    scoreProfileInfo: {
      immediate: true,
      handler(newV, oldV) {
        if (newV && this.flag) {
          this.scoreProfileData = this.scoreProfileInfo
        }
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {},

  methods: {
    scoreBillList() {
      console.log(111)
      this.$emit('goJiShen')
      // getScoreBillInfoAPI({ orderNo: this.scoreProfileData.orderNo }).then(res => {
      //   this.scoreBillInfoDialog = true
      //   this.scoreBillInfo = res
      // })
    },
    updateFinalGrade() {
      getDictEnumListAPI('member:grade').then(res => {
        this.gradeList = res
        this.finalGradeData.grade = this.scoreProfileData.finalHitGrade
        this.updateFinalGradeDialog = true
      })
    },
    clickFinalGrade(item) {
      this.finalGradeData.grade = item.value
    },
    saveFinalGrade() {
      let param = {
        orderNo: this.scoreProfileData.orderNo,
        grade: this.finalGradeData.grade,
        remark: this.finalGradeData.remark
      }
      updateFinalHitGradeAPI(param).then(() => {
        this.$message.success('保存成功')
        this.$emit('update:flag', true)
        this.$emit('update:updatePeriod', false)
        this.$emit('updateDetail')
        this.updateFinalGradeDialog = false
      })
    },
    // 保存资料
    saveProfile() {
      this.$refs.formScoreProfile.validate(valid => {
        if (valid) {
          hitOrderScoreProfileAPI(this.scoreProfileData).then(() => {
            this.$message.success('保存成功')
            this.$emit('update:flag', true)
            this.$emit('updateDetail')
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  padding: 10px;
  border: 1px solid #EBEEF5;
  .el-form-item__label {
    padding: 0;
  }
  .el-descriptions {
    font-size: 13px!important;
    .my-label {
      color: #1a1e25;
      background: #dadada;
    }
    .my-content {
      .handle {
        display: flex;
        justify-content: space-between;
        & > span {
          &:first-child {
            margin-right: 20px;
            flex: 1;
          }
        }
      }
    }
  }
  .score-profile-form {
    .el-form-item {
      margin-bottom: 0;
      .el-form-item__label {
        padding: 0;
      }
    }
  }
  .el-descriptions-item__cell {
    height: 50px;
  }
  .score-bill-title {
    & > span {
      margin-right: 50px;
      font-weight: 600;
    }
  }
  .score-bill-table {
    margin-top: 20px;
    .tp-table {
      .el-table__fixed-right {
        height: 100% !important;
      }
    }
  }
  .final-grade-remark {
    margin-top: 30px;
    span {
      margin-right: 10px;
    }
  }
  .lv {
    padding: 3px 10px;
    border-radius: 3px;
  }
  .lv-a {
    background-color: #b0ce87;
  }
  .lv-b {
    background-color: #4095e5;
  }
  .lv-c {
    background-color: #d3b465;
  }
  .lv-d {
    background-color: #bd3124;
  }
  .table-box {
    font-size: 13px;
    .el-form-item__label {
      padding: 0;
      margin-left: 4px;
    }
    th {
      padding: 7px;
      background-color: #dadada;
    }
    td {
      text-align: center;
      padding: 7px;
      color: #606266;
      border: 1px solid #ebeef5;
    }
    .table-bg1 {
      td {
        color: #333;
        white-space: nowrap;
        background-color: #dadada;
      }
    }
    .id-box {
      display: flex;
    }
  }
}
</style>
