<template>
  <div class="wrapper">
    <el-form ref="formJob" class="job-form" :model="jobData" label-width="">
      <el-descriptions style="margin-bottom:20px;" direction="vertical" :column="5" border>
        <el-descriptions-item label="工作单位" :span="2" label-class-name="my-label">
          <el-form-item label=" " prop="workCompany">
            <el-input style="width:250px;" v-model="jobData.workCompany" size="mini" placeholder="请输入"></el-input>
          </el-form-item>
        </el-descriptions-item>
        <el-descriptions-item label="岗位" :span="2" label-class-name="my-label">
          <el-form-item label=" " prop="jobCategory">
            <SelectDict v-model="jobData.jobCategory" size="mini" dictCode="member:jobCategory" width="150px"></SelectDict>
            <span style="margin-left:20px;margin-right:5px">岗位备注：</span>
            <span>
              <el-input style="width:200px;" v-model="jobData.jobName" size="mini" placeholder="请输入"></el-input>
            </span>
          </el-form-item>
          <!-- <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item label=" " prop="jobCategory" :rules="[{ required: true, message: '该项不能为空', trigger: 'blur' }]">
                <SelectDict v-model="jobData.jobCategory" size="mini" dictCode="member:jobCategory" width="150px"></SelectDict>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="岗位备注" prop="jobName" class="job-name">
                <el-input style="width:200px;" v-model="jobData.jobName" size="mini" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row> -->
        </el-descriptions-item>
        <el-descriptions-item label="薪资" :span="1" label-class-name="my-label">
          <el-form-item label=" " prop="income">
            <el-input style="width:250px;" v-model="jobData.income" size="mini" placeholder="请输入"></el-input>
          </el-form-item>
        </el-descriptions-item>
        <el-descriptions-item label="芝麻分" label-class-name="my-label">
          <el-form-item label=" " prop="zmScore" :rules="[{ required: true, message: '该项不能为空', trigger: 'blur' }]">
            <el-input style="width:250px;" v-model="jobData.zmScore" size="mini" placeholder="请输入"></el-input>
          </el-form-item>
        </el-descriptions-item>
        <el-descriptions-item label="在租详情" :span="2" label-class-name="my-label" content-class-name="my-content">
          <el-form-item label=" " prop="rentDetail">
            <div class="handle">
              <span>
                <el-input style="width:650px;" v-model="jobData.rentDetail" size="mini" placeholder="请输入"></el-input>
                <el-button style="margin-left:10px" type="primary" size="small" @click="saveJob">保存</el-button>
              </span>
            </div>
          </el-form-item>
        </el-descriptions-item>
      </el-descriptions>
    </el-form>
  </div>
</template>

<script>
import { saveMemberProfileAPI } from './api'
import SelectDict from '@/views/components/select-dict.vue'

export default {
  name: 'Job',
  components: { SelectDict },
  props: {
    jobInfo: {
      type: Object,
      default: () => {}
    },
    member: {
      type: Object,
      default: () => {}
    },
    flag: {
      type: Boolean,
      default: true
    },
    updatePeriod: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      jobData: {
        memberId: '',
        workCompany: '',
        jobCategory: '',
        jobName: '',
        income: '',
        zmScore: '',
        rentDetail: ''
      }
    }
  },
  watch: {
    jobInfo: {
      handler(newV, oldV) {
        if (newV && this.flag) {
          this.jobData = this.jobInfo
          // console.log('@@jobData', this.jobData)
        }
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {},

  methods: {
    // 保存工作信息
    saveJob() {
      this.jobData.memberId = this.member.id
      this.$refs.formJob.validate(valid => {
        if (valid) {
          saveMemberProfileAPI(this.jobData).then(() => {
            this.$message.success('保存成功')
            this.$emit('update:flag', true)
            this.$emit('update:updatePeriod', false)
            this.$emit('updateDetail')
          })
        } else {
          // this.$alert('工作单位不能为空', '提示', {
          //   confirmButtonText: '确定',
          //   callback: action => {}
          // })
        }
      })
      // if (!this.jobData.workCompany) {
      //   this.$alert('工作单位不能为空', '提示', {
      //     confirmButtonText: '确定',
      //     callback: action => {}
      //   })
      // } else {
      //   saveMemberProfileAPI(this.jobData).then(() => {
      //     this.$message.success('保存成功')
      //     this.$emit('updateDetail')
      //   })
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;
  .el-descriptions {
    .my-label {
      color: #1a1e25;
      background: #dadada;
    }
    .my-content {
      .handle {
        display: flex;
        justify-content: space-between;
        & > span {
          &:first-child {
            margin-right: 20px;
            flex: 1;
          }
        }
      }
    }
  }
  .job-form {
    .el-form-item {
      margin-bottom: 0;
    }
  }
}
</style>
