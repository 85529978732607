<template>
  <div class="wrapper">
    <!-- 基本信息 -->
    <BaseInfo :detailInfo="detailInfo" :flag.sync="flag" @updateDetail="getReviewOrderDetail" />
    <!-- 用户资料 -->
    <Job
        v-if="detailInfo.orderType !== 2"
        :flag.sync="flag"
        :jobInfo="detailInfo.memberProfile || {}"
        :member="detailInfo.member"
        @updateDetail="getReviewOrderDetail"
    />
    <ScoreProfile
        v-if="detailInfo.orderType === 2"
        :flag.sync="flag"
        :scoreProfileInfo="detailInfo.orderScoreProfile || {}"
        @updateDetail="getReviewOrderDetail"
    />
    <!-- 紧急联系人 -->
    <Contact :flag.sync="flag" :member="detailInfo.member" :orderInfo="detailInfo" @updateDetail="getReviewOrderDetail" />
    <!-- 收货信息 -->
    <div class="detail-title">收货信息</div>
    <Address :flag.sync="flag" :orderAddress="detailInfo.orderAddress" @updateDetail="getReviewOrderDetail" />
    <!-- 用户评分 -->
<!--    <div class="detail-title">用户评分</div>-->
<!--    <Rate :flag.sync="flag" :memberGradeLog="detailInfo.memberGradeLog || {}" @updateDetail="getReviewOrderDetail" />-->
    <!-- 客户归属 -->
<!--    <div class="detail-title">客户归属</div>-->
<!--    <SelectCustomer :flag.sync="flag" :kfUserId="detailInfo.kfUserId" @updateDetail="getReviewOrderDetail" />-->
    <!-- 账期 -->
    <div class="detail-title">
      账期
      <span v-if="detailInfo.orderType == 2" class="arbitrage">（套现空间：￥{{ detailInfo.arbitragePrice }}）</span>
    </div>
    <div style="margin-bottom:20px">
      <StorePeriod v-show="detailInfo.storeBillOptionList" :list="detailInfo.storeBillOptionList" @resetBill="resetBill"></StorePeriod>
      <Period
        v-show="!detailInfo.storeBillOptionList"
        ref="period"
        :billPeriodNum="detailInfo.billPeriodNum"
        :eachRentPrice="detailInfo.eachRentPrice"
      />
    </div>
    <!-- 审核结果 -->

    <!-- <div class="result">
      <div class="title">审核结果</div>
      <AuditResult :memberProfile="detailInfo.memberProfile || {}" :memberGradeLog="detailInfo.memberGradeLog || {}" />
    </div> -->
    <!-- <div style="height:80px"></div> -->
    <!-- <div style="height:130px"></div> -->
  </div>
</template>

<script>
import { getReviewOrderDetailAPI } from './api'
import BaseInfo from './modules/baseInfo.vue'
import Address from './modules/address.vue'
import Rate from './modules/rate.vue'
import SelectCustomer from './modules/select-customer.vue'
import Period from './modules/period.vue'
import AuditResult from './modules/review-result.vue'
import Job from './modules/job.vue'
import ScoreProfile from './modules/score-profile.vue'
import Contact from './modules/contact.vue'
import StorePeriod from './modules/store-period.vue'


export default {
  name: 'DetailBase',
  components: {
    BaseInfo,
    Address,
    Rate,
    SelectCustomer,
    Period,
    Job,
    Contact,
    StorePeriod,
    ScoreProfile,
  },
  data() {
    return {
      detailInfo: {},
      flag: true
    }
  },
  created() {
    // 可以更新账期
    localStorage.setItem('is_update_period', 'true')
  },
  mounted() {
    // 窗口关闭清除本地缓存
    let that = this
    window.onbeforeunload = () => {
      localStorage.removeItem('updateBill:' + that.detailInfo.orderNo)
      localStorage.removeItem('storeBillIndex:' + that.detailInfo.orderNo)
      localStorage.removeItem('storeBill:' + that.detailInfo.orderNo)
    }
  },
  activated() {
    this.getReviewOrderDetail()
  },
  deactivated() {
    // 页面失活清除本地缓存
    localStorage.removeItem('updateBill:' + this.detailInfo.orderNo)
    localStorage.removeItem('storeBillIndex:' + this.detailInfo.orderNo)
    localStorage.removeItem('storeBill:' + this.detailInfo.orderNo)
  },

  methods: {
    async getReviewOrderDetail() {
      this.detailInfo = await getReviewOrderDetailAPI(this.$route.params.id)
      if (localStorage.getItem('is_update_period') == 'true') {
        if (this.detailInfo.storeBillOptionList) {
          let storeBillOption = JSON.stringify(this.detailInfo.storeBillOptionList)
          localStorage.setItem('storeBill:' + this.detailInfo.orderNo, storeBillOption)
          localStorage.setItem('updateBill:' + this.detailInfo.orderNo, storeBillOption)
          this.$store.commit(
            'UPDATE_PERIOD',
            this.detailInfo.storeBillOptionList[localStorage.getItem('storeBillIndex:' + this.detailInfo.orderNo) - 0].prepayBill
          )
        } else {
          this.$refs.period.periodList = this.detailInfo.prepayPeriodList
          this.$store.commit('UPDATE_PERIOD', this.detailInfo.prepayPeriodList)
        }
      } else {
        if (this.detailInfo.storeBillOptionList) {
          Object.assign(this.detailInfo.storeBillOptionList, JSON.parse(localStorage.getItem('updateBill:' + this.detailInfo.orderNo)))
        }
      }
      // 设置账期不会随其他设置而改变
      localStorage.setItem('is_update_period', 'false')
      this.$emit('updateDetail', this.detailInfo)
    },
    resetBill({ bill, index }) {
      // 修改当前账单
      Object.assign(this.detailInfo.storeBillOptionList[index], bill)
      // 备份当前修改后的账单
      localStorage.setItem('updateBill:' + this.detailInfo.orderNo, JSON.stringify(this.detailInfo.storeBillOptionList))
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  padding-top: 15px;
  .detail-title {
    font-weight: 600;
    font-size: 14px;
    margin: 20px 0 10px 0;
  }
  .arbitrage {
    font-size: 12px;
    color: #f56c6c;
  }
  .result {
    position: sticky;
    bottom: -28px;
    left: 240px;
    right: 0;
    background-color: #fff;
    z-index: 3999;

    .title {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 10px;
      // margin-top: 20px;
    }
  }
}
</style>
